import React, { useEffect, useState } from 'react';
import { createUnsplashService, type UnsplashPhoto } from '../services/unsplash';
import { config } from '../config/environment';
import { UnsplashAttribution } from './UnsplashAttribution';
import { auth, db } from '../config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

interface BackgroundProps {
  children: React.ReactNode;
  forceRefresh?: number;
}

const unsplashService = createUnsplashService(config.unsplash.accessKey);

export function Background({ children, forceRefresh = 0 }: BackgroundProps) {
  const [photo, setPhoto] = useState<UnsplashPhoto | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState<'daily' | 'weekly'>('daily');
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const unsubAuth = auth.onAuthStateChanged(user => {
      if (user) {
        const unsubUser = onSnapshot(doc(db, 'users', user.uid), (doc) => {
          if (doc.exists()) {
            setRefreshInterval(doc.data().settings?.backgroundRefreshInterval || 'daily');
          }
        });
        return () => unsubUser();
      }
    });

    return () => unsubAuth();
  }, []);

  const fetchNewBackground = async (force: boolean = false) => {
    try {
      const photo = await unsplashService.getRandomPhoto();
      setPhoto(photo);
      
      if (!force) {
        localStorage.setItem('cachedBackground', JSON.stringify(photo));
        localStorage.setItem('lastBackgroundFetch', Date.now().toString());
      }
      
      return true;
    } catch (error) {
      console.error('Error fetching background:', error);
      return false;
    }
  };

  useEffect(() => {
    const loadBackground = async () => {
      setIsLoading(true);
      setImageLoaded(false);
      
      try {
        if (forceRefresh > 0) {
          await fetchNewBackground(true);
          return;
        }

        const cachedPhoto = localStorage.getItem('cachedBackground');
        const lastFetch = localStorage.getItem('lastBackgroundFetch');
        
        if (!cachedPhoto || !lastFetch) {
          await fetchNewBackground();
          return;
        }

        const cacheAge = Date.now() - parseInt(lastFetch, 10);
        const maxAge = refreshInterval === 'daily' ? 24 * 60 * 60 * 1000 : 7 * 24 * 60 * 60 * 1000;
        
        if (cacheAge >= maxAge) {
          await fetchNewBackground();
        } else {
          setPhoto(JSON.parse(cachedPhoto));
        }
      } catch (error) {
        console.error('Error loading background:', error);
        const cached = localStorage.getItem('cachedBackground');
        if (cached) {
          setPhoto(JSON.parse(cached));
        }
      } finally {
        setIsLoading(false);
      }
    };

    loadBackground();
  }, [refreshInterval, forceRefresh]);

  useEffect(() => {
    if (photo) {
      const img = new Image();
      img.src = photo.urls.full;
      img.onload = () => setImageLoaded(true);
    }
  }, [photo]);

  if (!photo) return null;

  return (
    <div className="relative min-h-screen">
      <div 
        className={`fixed inset-0 transition-opacity duration-1000 ${
          isLoading || !imageLoaded ? 'opacity-0' : 'opacity-100'
        }`}
        style={{
          backgroundImage: `url(${photo.urls.full})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          imageRendering: 'crisp-edges'
        }}
      />
      {!imageLoaded && (
        <div 
          className="fixed inset-0 transition-opacity duration-1000"
          style={{
            backgroundImage: `url(${photo.urls.regular})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(8px)',
            transform: 'scale(1.1)'
          }}
        />
      )}
      <div className="fixed inset-0 bg-black/30" />
      <div className="relative text-white min-h-screen">
        {children}
      </div>
      <UnsplashAttribution photographer={photo.user} photoUrl={photo.links.html} />
    </div>
  );
}