// Chrome API mock for development environment
const createChromeApiMock = () => {
  const storage = {
    sync: {
      get: (keys: string | string[] | object | null, callback: (result: any) => void) => {
        try {
          const result: Record<string, any> = {};
          
          if (typeof keys === 'string') {
            result[keys] = localStorage.getItem(keys) ? JSON.parse(localStorage.getItem(keys)!) : null;
          } else if (Array.isArray(keys)) {
            keys.forEach(key => {
              result[key] = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)!) : null;
            });
          } else if (keys === null) {
            // Get all items from localStorage
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i)!;
              result[key] = JSON.parse(localStorage.getItem(key)!);
            }
          } else {
            // Handle object case
            Object.keys(keys).forEach(key => {
              const value = localStorage.getItem(key);
              result[key] = value ? JSON.parse(value) : (keys as Record<string, any>)[key];
            });
          }
          
          callback(result);
        } catch (error) {
          console.error('Storage error:', error);
          callback({});
        }
      },
      set: (items: object, callback?: () => void) => {
        try {
          Object.entries(items).forEach(([key, value]) => {
            localStorage.setItem(key, JSON.stringify(value));
          });
          if (callback) callback();
        } catch (error) {
          console.error('Storage error:', error);
          if (callback) callback();
        }
      },
      remove: (keys: string | string[], callback?: () => void) => {
        try {
          const keysArray = typeof keys === 'string' ? [keys] : keys;
          keysArray.forEach(key => localStorage.removeItem(key));
          if (callback) callback();
        } catch (error) {
          console.error('Storage error:', error);
          if (callback) callback();
        }
      },
      clear: (callback?: () => void) => {
        try {
          localStorage.clear();
          if (callback) callback();
        } catch (error) {
          console.error('Storage error:', error);
          if (callback) callback();
        }
      }
    }
  };

  return { storage };
};

// Use the mock in development and the real Chrome API in production
export const chrome = (typeof window !== 'undefined' && 'chrome' in window && window.chrome?.storage) 
  ? window.chrome 
  : createChromeApiMock();