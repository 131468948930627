import React, { useEffect, useState } from 'react';

export function Clock() {
  const [time, setTime] = useState('');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      setTime(now.toLocaleTimeString('en-US', { 
        hour: 'numeric', 
        minute: '2-digit',
        hour12: true 
      }));
      
      const hour = now.getHours();
      if (hour < 12) setGreeting('Good morning');
      else if (hour < 18) setGreeting('Good afternoon');
      else setGreeting('Good night');
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-center mt-8 sm:mt-0">
      <h1 className="text-6xl sm:text-8xl font-light mb-2 sm:mb-4">{time}</h1>
      <h2 className="text-2xl sm:text-4xl font-light mb-8 sm:mb-12">{greeting}</h2>
    </div>
  );
}