import React from 'react';
import { Camera } from 'lucide-react';

interface UnsplashAttributionProps {
  photographer: {
    name: string;
    username: string;
    links: {
      html: string;
    };
  };
  photoUrl: string;
}

export function UnsplashAttribution({ photographer, photoUrl }: UnsplashAttributionProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-center pb-4">
      <div className="text-white/70 text-sm flex items-center gap-2">
        <Camera className="w-4 h-4" />
        <span>
          <a
            href={photoUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white/90 transition-colors"
          >
            Photo
          </a>
          {' '}by{' '}
          <a
            href={photographer.links.html}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white/90 transition-colors"
          >
            {photographer.name}
          </a>
          {' '}on{' '}
          <a
            href="https://unsplash.com/?utm_source=tabistry&utm_medium=referral"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white/90 transition-colors"
          >
            Unsplash
          </a>
        </span>
      </div>
    </div>
  );
}