import React, { useEffect, useState } from 'react';
import { ExternalLink } from 'lucide-react';
import { auth, db } from '../config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';

export function Advertisement() {
  const [isPro, setIsPro] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubAuth = auth.onAuthStateChanged(user => {
      if (user) {
        const unsubUser = onSnapshot(doc(db, 'users', user.uid), (doc) => {
          if (doc.exists()) {
            setIsPro(doc.data().settings?.isPro || false);
          }
        });
        return () => unsubUser();
      } else {
        setIsPro(false);
      }
      setIsLoading(false);
    });

    return () => unsubAuth();
  }, []);

  if (isLoading || isPro) return null;

  return (
    <div className="fixed right-0 sm:right-4 bottom-32 sm:bottom-16 left-0 sm:left-auto mx-4 sm:mx-0 bg-white/10 backdrop-blur-md rounded-lg p-4 text-white sm:max-w-sm shadow-lg border border-white/10">
      <div className="flex items-start gap-3">
        <img 
          src="https://media.unstack.com/media/jobmate/image/thumbnail/jobmate.png" 
          alt=""
          className="w-12 h-12 rounded-lg object-cover flex-shrink-0"
          loading="lazy"
        />
        <div className="flex-1 min-w-0">
          <div className="text-xs text-white/60 mb-1">Sponsored by:</div>
          <h3 className="font-medium text-lg mb-1 line-clamp-1">
            Applying to Jobs Sucks!
          </h3>
          <p className="text-sm text-white/90 mb-2 line-clamp-3">
            Let JobMate apply to jobs with your target title and location. You'll save yourself 12-15 hours per week.
          </p>
          <a
            href="https://getjobmate.com/"
            target="_blank"
            rel="noopener"
            className="text-sm text-blue-300 hover:text-blue-200 inline-flex items-center gap-1.5 transition-colors underline underline-offset-2"
          >
            Get Started
            <ExternalLink className="w-3.5 h-3.5" />
          </a>
        </div>
      </div>
    </div>
  );
}