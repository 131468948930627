import { 
  signInWithPopup, 
  signInWithRedirect, 
  GoogleAuthProvider,
  getRedirectResult,
  Auth,
  AuthError,
  browserPopupRedirectResolver
} from 'firebase/auth';
import { doc, getDoc, setDoc, Firestore } from 'firebase/firestore';
import type { UserSettings } from '../types';
import { isDevelopment } from '../config/environment';
import { waitForFirebaseConnection } from './firebase';

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ 
  prompt: 'select_account'
});

export const signInWithGoogle = async (auth: Auth, db: Firestore): Promise<void> => {
  const isConnected = await waitForFirebaseConnection(db);
  if (!isConnected) {
    throw new Error('Unable to connect to authentication service');
  }

  try {
    const result = await signInWithPopup(auth, provider, browserPopupRedirectResolver);
    if (result.user) {
      await initializeUserData(db, result.user.uid, result.user.email);
    }
  } catch (error: any) {
    console.error('Popup sign-in error:', error);
    
    if (error.code === 'auth/popup-blocked' || error.code === 'auth/cancelled-popup-request') {
      await signInWithRedirect(auth, provider);
    } else {
      throw error;
    }
  }
};

export const handleAuthRedirect = async (auth: Auth): Promise<void> => {
  try {
    const result = await getRedirectResult(auth, browserPopupRedirectResolver);
    if (result?.user) {
      console.log('Redirect sign-in successful');
    }
  } catch (error) {
    console.error('Redirect sign-in error:', error);
    throw error;
  }
};

export const getDefaultSettings = (): UserSettings => ({
  backgroundRefreshInterval: 'daily',
  showTime: true,
  isPro: false
});

export const initializeUserData = async (
  db: Firestore,
  userId: string,
  email: string | null
): Promise<UserSettings> => {
  const userRef = doc(db, 'users', userId);
  
  try {
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      return userDoc.data().settings || getDefaultSettings();
    }

    const userData = {
      email,
      settings: getDefaultSettings(),
      createdAt: new Date(),
      updatedAt: new Date()
    };

    await setDoc(userRef, userData);
    return userData.settings;
  } catch (error) {
    console.error('Error initializing user data:', error);
    return getDefaultSettings();
  }
};

export const getAuthErrorMessage = (error: AuthError): string => {
  switch (error.code) {
    case 'auth/popup-blocked':
      return 'Popup was blocked. Redirecting to sign-in page...';
    case 'auth/cancelled-popup-request':
      return 'Sign-in was cancelled. Please try again.';
    case 'auth/network-request-failed':
      return 'Network error. Please check your connection and try again.';
    case 'auth/unauthorized-domain':
      return 'This domain is not authorized for authentication. Please try again later.';
    case 'auth/invalid-api-key':
      return 'Authentication service is temporarily unavailable. Please try again later.';
    case 'auth/operation-not-allowed':
      return 'Google sign-in is not enabled. Please contact support.';
    case 'auth/user-disabled':
      return 'This account has been disabled. Please contact support.';
    default:
      return 'An error occurred during sign-in. Please try again.';
  }
};