import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';

export interface Bookmark {
  id: string;
  title: string;
  url: string;
}

interface BookmarksProps {
  bookmarks: Bookmark[];
  onAdd: (bookmark: Omit<Bookmark, 'id'>) => void;
  onRemove: (id: string) => void;
}

export function Bookmarks({ bookmarks, onAdd, onRemove }: BookmarksProps) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [newBookmark, setNewBookmark] = useState({ title: '', url: '' });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newBookmark.title && newBookmark.url) {
      onAdd(newBookmark);
      setNewBookmark({ title: '', url: '' });
      setShowAddForm(false);
    }
  };

  return (
    <div className="fixed bottom-20 left-0 right-0">
      <div className="max-w-4xl mx-auto px-6">
        <div className="flex justify-center gap-3 items-center">
          {bookmarks.map(bookmark => (
            <a
              key={bookmark.id}
              href={bookmark.url}
              className="group px-3 py-1.5 rounded-full bg-black/20 hover:bg-black/30 transition-all flex items-center gap-1.5"
            >
              <span className="text-sm text-white/90">{bookmark.title}</span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove(bookmark.id);
                }}
                className="opacity-0 group-hover:opacity-100 transition-opacity"
                aria-label={`Remove ${bookmark.title}`}
              >
                <X size={12} className="text-white/70 hover:text-white/90" />
              </button>
            </a>
          ))}
          <button
            onClick={() => setShowAddForm(true)}
            className="px-2 py-1.5 rounded-full bg-black/20 hover:bg-black/30 transition-all"
            aria-label="Add bookmark"
          >
            <Plus size={14} className="text-white/70" />
          </button>
        </div>
      </div>

      {showAddForm && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <form onSubmit={handleSubmit} className="bg-white/10 backdrop-blur-md p-6 rounded-lg w-96">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-medium">Add Bookmark</h4>
              <button type="button" onClick={() => setShowAddForm(false)}>
                <X size={20} />
              </button>
            </div>
            <input
              type="text"
              placeholder="Title"
              className="w-full bg-white/10 p-2 rounded mb-3"
              value={newBookmark.title}
              onChange={e => setNewBookmark(prev => ({ ...prev, title: e.target.value }))}
            />
            <input
              type="url"
              placeholder="URL"
              className="w-full bg-white/10 p-2 rounded mb-4"
              value={newBookmark.url}
              onChange={e => setNewBookmark(prev => ({ ...prev, url: e.target.value }))}
            />
            <button
              type="submit"
              className="w-full bg-white/20 hover:bg-white/30 p-2 rounded transition-colors"
            >
              Add Bookmark
            </button>
          </form>
        </div>
      )}
    </div>
  );
}