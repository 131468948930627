import { createApi } from 'unsplash-js';
import type { Random } from 'unsplash-js/dist/methods/photos/types';

export interface UnsplashPhoto {
  urls: {
    regular: string;
    full: string;
  };
  user: {
    name: string;
    username: string;
    links: {
      html: string;
    };
  };
  links: {
    html: string;
  };
}

const FALLBACK_BACKGROUNDS: UnsplashPhoto[] = [
  {
    urls: {
      regular: 'https://images.unsplash.com/photo-1506744038136-46273834b3fb',
      full: 'https://images.unsplash.com/photo-1506744038136-46273834b3fb'
    },
    user: {
      name: 'Bailey Zindel',
      username: 'baileyzindel',
      links: {
        html: 'https://unsplash.com/@baileyzindel'
      }
    },
    links: {
      html: 'https://unsplash.com/photos/NRQV-hBF10Y'
    }
  },
  {
    urls: {
      regular: 'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05',
      full: 'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05'
    },
    user: {
      name: 'Lukasz Szmigiel',
      username: 'szmigieldesign',
      links: {
        html: 'https://unsplash.com/@szmigieldesign'
      }
    },
    links: {
      html: 'https://unsplash.com/photos/jFCViYFYcus'
    }
  }
];

class UnsplashService {
  private api: ReturnType<typeof createApi>;
  private retryAttempts: number = 3;
  private retryDelay: number = 1000;

  constructor(accessKey: string) {
    this.api = createApi({
      accessKey,
      fetch: window.fetch.bind(window)
    });
  }

  async getRandomPhoto(): Promise<UnsplashPhoto> {
    let lastError: Error | null = null;

    for (let attempt = 1; attempt <= this.retryAttempts; attempt++) {
      try {
        const result = await this.api.photos.getRandom({
          query: 'nature landscape',
          orientation: 'landscape',
          content_filter: 'high'
        });

        if (result.type === 'success' && result.response) {
          const photo = Array.isArray(result.response) 
            ? result.response[0] 
            : result.response as Random;

          await this.trackPhotoView(photo.links.html);
          
          return {
            urls: photo.urls,
            user: {
              name: photo.user.name,
              username: photo.user.username,
              links: photo.user.links
            },
            links: photo.links
          };
        }
        
        throw new Error('Invalid response from Unsplash');
      } catch (error) {
        lastError = error as Error;
        
        if (attempt < this.retryAttempts) {
          await new Promise(resolve => 
            setTimeout(resolve, this.retryDelay * attempt)
          );
          continue;
        }
      }
    }

    console.error('Failed to fetch from Unsplash:', lastError);
    return this.getFallbackPhoto();
  }

  private getFallbackPhoto(): UnsplashPhoto {
    return FALLBACK_BACKGROUNDS[
      Math.floor(Math.random() * FALLBACK_BACKGROUNDS.length)
    ];
  }

  private async trackPhotoView(photoUrl: string): Promise<void> {
    try {
      await this.api.photos.trackView({ downloadLocation: photoUrl });
    } catch (error) {
      console.warn('Failed to track photo view:', error);
    }
  }
}

export const createUnsplashService = (accessKey: string): UnsplashService => {
  if (!accessKey) {
    console.warn('Unsplash access key not configured');
  }
  return new UnsplashService(accessKey || '');
};