import React, { useState, useEffect } from 'react';
import { Settings as SettingsIcon, X, LogIn, AlertCircle, RefreshCw, Crown } from 'lucide-react';
import { auth, db } from '../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import type { UserSettings } from '../types';
import { signInWithGoogle, handleAuthRedirect, initializeUserData, getDefaultSettings, getAuthErrorMessage } from '../services/auth';
import { redirectToCheckout } from '../services/stripe';

interface SettingsProps {
  onRefreshBackground?: () => void;
}

export function Settings({ onRefreshBackground }: SettingsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [settings, setSettings] = useState<UserSettings>(getDefaultSettings());
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        setError('');
        try {
          const userSettings = await initializeUserData(db, user.uid, user.email);
          setSettings(userSettings);
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load settings. Please try again.');
        }
      } else {
        setUser(null);
        setSettings(getDefaultSettings());
      }
    });

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    if (isSigningIn || isRedirecting) return;
    
    setIsSigningIn(true);
    setError('');

    try {
      await signInWithGoogle(auth, db);
    } catch (error: any) {
      console.error('Sign-in error:', error);
      
      if (error.code === 'auth/popup-blocked') {
        setIsRedirecting(true);
        setError('Popup blocked. Redirecting to sign-in page...');
      } else {
        setError(getAuthErrorMessage(error));
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const handleUpgradeToPro = async () => {
    if (!user || isProcessingPayment) return;

    setIsProcessingPayment(true);
    setError('');

    try {
      await redirectToCheckout();
    } catch (error) {
      console.error('Upgrade error:', error);
      setError('Failed to start checkout. Please try again.');
    } finally {
      setIsProcessingPayment(false);
    }
  };

  const handleRefreshBackground = async () => {
    if (isRefreshing || !onRefreshBackground) return;
    
    setIsRefreshing(true);
    setError('');
    
    try {
      localStorage.removeItem('cachedBackground');
      localStorage.removeItem('lastBackgroundFetch');
      onRefreshBackground();
      await new Promise(resolve => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error refreshing background:', error);
      setError('Failed to refresh background. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  const updateSettings = async (newSettings: Partial<UserSettings>) => {
    if (!user) return;

    try {
      const updatedSettings = { ...settings, ...newSettings };
      await updateDoc(doc(db, 'users', user.uid), {
        settings: updatedSettings,
        updatedAt: new Date()
      });
      setSettings(updatedSettings);
      setError('');
    } catch (error) {
      console.error('Error updating settings:', error);
      setError('Failed to update settings. Please try again.');
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed top-6 right-6 opacity-50 hover:opacity-100 transition-opacity"
        aria-label="Settings"
      >
        <SettingsIcon className="w-4 h-4 text-white" strokeWidth={1.5} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white/10 backdrop-blur-md rounded-lg w-full max-w-md">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-light">Settings</h2>
                <button onClick={() => setIsOpen(false)}>
                  <X className="w-5 h-5" />
                </button>
              </div>

              {error && (
                <div className="mb-4 p-3 bg-red-500/20 border border-red-500/30 rounded-lg flex items-center gap-2">
                  <AlertCircle className="w-5 h-5 text-red-400" />
                  <p className="text-sm text-red-200">{error}</p>
                </div>
              )}

              {!user ? (
                <button
                  onClick={handleSignIn}
                  disabled={isSigningIn || isRedirecting}
                  className={`w-full bg-white/20 hover:bg-white/30 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors ${
                    (isSigningIn || isRedirecting) ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                >
                  <LogIn className="w-5 h-5" />
                  {isSigningIn ? 'Signing in...' : isRedirecting ? 'Redirecting...' : 'Sign in with Google'}
                </button>
              ) : (
                <div className="space-y-6">
                  {settings.isPro ? (
                    <div className="bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg p-4 border border-white/10">
                      <div className="flex items-center gap-2 text-lg font-medium mb-1">
                        <Crown className="w-5 h-5 text-yellow-400" />
                        Pro Member
                      </div>
                      <p className="text-sm text-white/80">
                        Thank you for supporting Tabistry! Enjoy your ad-free experience.
                      </p>
                    </div>
                  ) : (
                    <button
                      onClick={handleUpgradeToPro}
                      disabled={isProcessingPayment}
                      className={`w-full bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 p-3 rounded-lg flex items-center justify-center gap-2 transition-colors ${
                        isProcessingPayment ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                    >
                      <Crown className="w-5 h-5" />
                      {isProcessingPayment ? 'Processing...' : 'Upgrade to Pro - $9'}
                    </button>
                  )}

                  <div>
                    <h3 className="text-lg font-medium mb-3">Display Preferences</h3>
                    <div className="space-y-3">
                      <label className="flex items-center justify-between">
                        <span>Show Time</span>
                        <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
                          <input
                            type="checkbox"
                            checked={settings.showTime}
                            onChange={(e) => updateSettings({ showTime: e.target.checked })}
                            className="opacity-0 w-0 h-0"
                          />
                          <span 
                            className={`absolute cursor-pointer inset-0 rounded-full transition-colors ${
                              settings.showTime ? 'bg-white/30' : 'bg-white/10'
                            }`}
                          >
                            <span 
                              className={`absolute w-4 h-4 bg-white rounded-full transition-transform transform ${
                                settings.showTime ? 'translate-x-6' : 'translate-x-1'
                              } top-1`}
                            />
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium mb-3">Background Image</h3>
                    <div className="space-y-4">
                      <div className="space-y-2">
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            checked={settings.backgroundRefreshInterval === 'daily'}
                            onChange={() => updateSettings({ backgroundRefreshInterval: 'daily' })}
                            className="text-white"
                          />
                          Change background daily
                        </label>
                        <label className="flex items-center gap-2">
                          <input
                            type="radio"
                            checked={settings.backgroundRefreshInterval === 'weekly'}
                            onChange={() => updateSettings({ backgroundRefreshInterval: 'weekly' })}
                            className="text-white"
                          />
                          Change background weekly
                        </label>
                      </div>
                      
                      <button
                        onClick={handleRefreshBackground}
                        disabled={isRefreshing}
                        className={`w-full flex items-center justify-center gap-2 bg-white/20 hover:bg-white/30 p-2 rounded-lg transition-colors ${
                          isRefreshing ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                      >
                        <RefreshCw className={`w-4 h-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                        {isRefreshing ? 'Refreshing...' : 'Refresh Background Now'}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}