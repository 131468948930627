import { doc, getDoc, setDoc, Firestore } from 'firebase/firestore';

const CONNECTIVITY_TEST_DOC = 'settings/connectivity_test';
const ADVERTISEMENT_DOC = 'settings/advertisement';
const MAX_RETRY_DELAY = 5000;

const DEFAULT_ADVERTISEMENT = {
  title: 'Upgrade to Tabistry Premium',
  body: 'Enjoy an ad-free experience, custom themes, and premium backgrounds with Tabistry Premium. Start your free trial today!',
  cta: 'Try Premium',
  ctaUrl: 'https://tabistry.com/premium',
  iconUrl: 'https://images.unsplash.com/photo-1614741118887-7a4ee193a5fa?q=80&w=64&h=64&fit=crop',
  isActive: true,
  createdAt: new Date(),
  updatedAt: new Date()
};

export const initializeDefaultAd = async (db: Firestore): Promise<void> => {
  try {
    const adRef = doc(db, ADVERTISEMENT_DOC);
    const adDoc = await getDoc(adRef);
    
    if (!adDoc.exists()) {
      await setDoc(adRef, DEFAULT_ADVERTISEMENT);
      console.log('Default advertisement initialized');
    }
  } catch (error) {
    console.error('Failed to initialize default advertisement:', error);
    // Retry once after a short delay
    setTimeout(async () => {
      try {
        const adRef = doc(db, ADVERTISEMENT_DOC);
        await setDoc(adRef, DEFAULT_ADVERTISEMENT, { merge: true });
      } catch (retryError) {
        console.error('Retry failed to initialize default advertisement:', retryError);
      }
    }, 2000);
  }
};

export const checkFirebaseConnection = async (
  db: Firestore,
  timeout = 10000,
  retries = 3
): Promise<boolean> => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const testRef = doc(db, CONNECTIVITY_TEST_DOC);
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Connection timeout')), timeout);
      });

      await Promise.race([getDoc(testRef), timeoutPromise]);
      
      if (attempt > 1) {
        console.log('Firebase connection established after retry');
      }
      
      // Initialize default ad after successful connection
      await initializeDefaultAd(db);
      return true;
    } catch (error) {
      console.warn(`Firebase connection attempt ${attempt}/${retries} failed:`, error);
      
      if (attempt === retries) {
        return false;
      }
      
      const delay = Math.min(1000 * Math.pow(2, attempt - 1), MAX_RETRY_DELAY);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
  return false;
};

export const waitForFirebaseConnection = async (
  db: Firestore,
  maxAttempts = 5
): Promise<boolean> => {
  for (let i = 0; i < maxAttempts; i++) {
    const isConnected = await checkFirebaseConnection(db);
    if (isConnected) return true;
    await new Promise(resolve => setTimeout(resolve, 2000));
  }
  return false;
};