import React, { useState } from 'react';
import { HelpCircle, X, ExternalLink } from 'lucide-react';

export function HelpModal() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed top-6 left-6 opacity-50 hover:opacity-100 transition-opacity"
        aria-label="Help"
      >
        <HelpCircle className="w-4 h-4 text-white" strokeWidth={1.5} />
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white/10 backdrop-blur-md rounded-lg w-full max-w-md p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-light">Features Guide</h2>
              <button onClick={() => setIsOpen(false)}>
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-2">Beautiful Backgrounds</h3>
                <p className="text-white/80">
                  The background image updates once per day with stunning photography from Unsplash.
                </p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">Quick Bookmarks</h3>
                <p className="text-white/80">
                  Add and manage your favorite websites using the bookmark bar at the bottom of the page.
                </p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">Personalization</h3>
                <p className="text-white/80">
                  Sign in to customize your experience! Change display preferences and background settings in the top-right settings menu.
                </p>
              </div>

              <div>
                <h3 className="text-lg font-medium mb-2">Premium Features</h3>
                <p className="text-white/80">
                  Remove ads and unlock additional features by{' '}
                  <a 
                    href="https://tabistry.com/pro/"
                    target="_blank"
                    rel="noopener"
                    className="text-blue-300 hover:text-blue-200 inline-flex items-center gap-1.5 transition-colors underline underline-offset-2"
                  >
                    upgrading to Premium here
                    <ExternalLink className="w-3.5 h-3.5" />
                  </a>
                </p>
              </div>

              <div className="pt-4 text-center text-sm text-white/60">
                created with ❤️ by{' '}
                <a 
                  href="https://x.com/kensavage" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-white/80 hover:text-white transition-colors"
                >
                  @kensavage
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}