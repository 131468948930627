import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../config/firebase';
import type { StripeCheckoutResponse } from '../types';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export const createCheckoutSession = async (): Promise<string> => {
  const user = auth.currentUser;
  if (!user) throw new Error('Must be logged in');

  try {
    const response = await fetch('/.netlify/functions/create-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: user.uid,
        userEmail: user.email,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Failed to create checkout session');
    }

    if (!data.sessionId) {
      throw new Error('Invalid response from server');
    }

    return data.sessionId;
  } catch (error: any) {
    console.error('Checkout error:', error);
    throw new Error(error.message || 'Failed to create checkout session');
  }
};

export const redirectToCheckout = async () => {
  try {
    const stripe = await stripePromise;
    if (!stripe) throw new Error('Stripe failed to load');

    const sessionId = await createCheckoutSession();
    const { error } = await stripe.redirectToCheckout({ sessionId });

    if (error) {
      throw error;
    }
  } catch (error: any) {
    console.error('Error in redirectToCheckout:', error);
    throw new Error(error.message || 'Failed to start checkout');
  }
};