import React from 'react';
import { Clock } from './Clock';

interface CenterDisplayProps {
  showTime: boolean;
}

export function CenterDisplay({ showTime }: CenterDisplayProps) {
  return (
    <div className="space-y-8">
      {showTime && <Clock />}
    </div>
  );
}