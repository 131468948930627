import React, { useEffect, useState } from 'react';
import { Background } from '../components/Background';
import { CenterDisplay } from '../components/CenterDisplay';
import { Bookmarks, type Bookmark } from '../components/Bookmarks';
import { Settings } from '../components/Settings';
import { HelpModal } from '../components/HelpModal';
import { Advertisement } from '../components/Advertisement';
import { chrome } from '../utils/chrome';
import { auth, db } from '../config/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import type { UserSettings } from '../types';

export default function HomePage() {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [settings, setSettings] = useState<UserSettings>({
    backgroundRefreshInterval: 'daily',
    showTime: true
  });
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    chrome.storage.sync.get(['bookmarks'], (result) => {
      if (result.bookmarks) {
        setBookmarks(result.bookmarks);
      }
    });

    const unsubAuth = auth.onAuthStateChanged(user => {
      if (user) {
        const unsubUser = onSnapshot(doc(db, 'users', user.uid), (doc) => {
          if (doc.exists()) {
            setSettings(doc.data().settings || {
              backgroundRefreshInterval: 'daily',
              showTime: true
            });
          }
        });
        return () => unsubUser();
      } else {
        setSettings({
          backgroundRefreshInterval: 'daily',
          showTime: true
        });
      }
    });

    return () => unsubAuth();
  }, []);

  const addBookmark = (newBookmark: Omit<Bookmark, 'id'>) => {
    const bookmark = {
      id: Date.now().toString(),
      ...newBookmark
    };
    const updatedBookmarks = [...bookmarks, bookmark];
    setBookmarks(updatedBookmarks);
    chrome.storage.sync.set({ bookmarks: updatedBookmarks });
  };

  const removeBookmark = (id: string) => {
    const updatedBookmarks = bookmarks.filter(b => b.id !== id);
    setBookmarks(updatedBookmarks);
    chrome.storage.sync.set({ bookmarks: updatedBookmarks });
  };

  const handleRefreshBackground = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  return (
    <Background forceRefresh={refreshTrigger}>
      <div className="flex flex-col min-h-screen">
        <div className="flex-1 flex flex-col sm:items-center sm:justify-center">
          <CenterDisplay showTime={settings.showTime} />
        </div>
        <Bookmarks 
          bookmarks={bookmarks}
          onAdd={addBookmark}
          onRemove={removeBookmark}
        />
      </div>
      <Settings onRefreshBackground={handleRefreshBackground} />
      <HelpModal />
      <Advertisement />
    </Background>
  );
}